html {
    position: relative;
    min-height: 100%;
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

body {
    /* Margin bottom by footer height */
    margin-bottom: 60px;
    background: #eee;
    background-image: url(/images/background-min.jpg) !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover !important;
}

body.page {
    background: #fff;
    background-image: none !important;
}
@media (max-width: 768px) {
    .px-1-sm {
        padding-left:5px;
        padding-right:5px;
    }
}

h1, h2, h3 {
    font-family: 'arial black';
}
h1 {
    font-size: 1.5rem;
    text-shadow: 0px 2px #cccccc;
    text-transform: uppercase;
}
label{
    font-weight:500;
}
label-small {font-size:90%;}

.ltr {
    direction: ltr;
}
.rtl {
    direction: rtl;
}
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}
.modal-backdrop {
    background-color: rgba(0,0,0,.3) !important;
}
code {
  color: #E01A76;
}
.form-control{
    font-weight:500;
}
.dropdown-show {
    width: 237px;
    position: absolute;
    top: -148px;
    left: 0px;
    display: block;
}
@media (max-width: 768px) {
    .dropdown-show {
        top:-129px;
    }
}

.visually-hidden, .visually-hidden-focusable:not(:focus) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0,0,0,0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.table-hover tr:hover td.bg-light {
    background-color: rgba(0, 0, 0, 0.015)!important;
} 

/* BORDERS */

.border-top {
    border-top: 1px solid #e5e5e5 !important;
}
.border-rightleft-dark {
    border-left: solid 2px #999;
    border-right: solid 2px #999;
}
.border-size-3 {
    border-width:3px !important;
}
.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

/* FIXED WIDTHS */
.w-100p {
    width: 100px;
    max-width: 100px;
}
.w-200p {
    width: 200px;
    max-width: 200px;
}
.w-90p {
    width: 90px;
    max-width: 90px;
}
.w-25p {    width: 25px;
    max-width: 25px;
}
.w-70per {
    width: 70%;
}
.h-41{height:41px;}

/* ICONS*/
.bi {
    display: inline-block;
}
.bi-file-plus{
    width: 1.25em;
    height: 1.25em;
    vertical-align: -.3em;
}

/* BUTTONS bootstrap 5 */
.btn-check:active + .btn-success, .btn-check:checked + .btn-success, .btn-success.active, .btn-success:active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #146c43;
    border-color: #13653f;
}
.btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
}
.btn-check:active + .btn-primary, .btn-check:checked + .btn-primary, .btn-primary.active, .btn-primary:active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0a58ca;
    border-color: #0a53be;
}
.btn-primary:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
}
.btn.disabled, .btn:disabled {
    opacity:.5;
    color: #fff !important;
    background-color: #343a40 !important;
    border-color: #343a40 !important;
}


