.roundprediction input[type=checkbox] + label {
    font-size: 11px;
    color: #000;
    text-align: center;
    background: #fff;
    display: inline-block;
    padding: 0 0 0 0;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: solid 1px #999;
    margin-bottom: 0;
    margin-left: -13px;
}

.roundprediction input[type=checkbox] + label span {
    padding: 10px;
}

.roundprediction input[type=checkbox]:checked + label {
    background: #212529;
    color: #fff;
}

.table.table-coupon tr:nth-child(3n+3) {
    border-bottom: solid 2px #6c757d;
}
.table.table-coupon tr:nth-child(13) {
    border-bottom: 2px solid #dee2e6;
}

.table.table-coupon tr:nth-child(12) {
    border-bottom: none;
}

.table-lightsecond {
    background-color: #f2f2f2 !important;
}
.percent span, .percentinfo span {
    font-size:.95em;
    width: 17px;
    display: inline-block;
    text-align: center;
}
.percent span{
    width:35px;
}
