a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
.navbar-brand {
    color: #fed189 !important;
    font-size: 26px;
    padding-bottom: 0;
    padding-top: 0;
}
    .navbar-brand .lite {
        color: #00a0df !important;
    }
